@import "../../main.scss";

.storyWrap {
  position: relative;
  background-image: url("../../assets/img/story_bg.jpg");
  background-size: cover;
  color: #fff;
  padding-top: 180px;
  overflow-x: hidden;

  &::before {
    background-color: #000;
    width: 100%;
    height: 100%;
    content: "";
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
  }

  .storyDescription {
    position: absolute;
    top: 160px;
    left: 50%;
    transform: translate(-50%, -50%);

    h2 {
      color: #41A4FF;
      font-size: 3.75rem;
      font-weight: bold;
      text-align: center;
    }

    p {
      color: #fff;
      font-size: 1.2rem;
      line-height: 2;
      text-align: center;
      margin-top: 30px;
    }

    a {
      width: fit-content;
      margin: 0 auto;
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 1.25rem;
      text-decoration: underline;
      text-align: center;
      cursor: pointer;
      margin-top: 30px;

      img {
        width: 1.875rem;
        height: 1.875rem;
        margin-right: 10px;
      }
    }
  }


  .swiperWrap {
    height: 180px;
    margin-top: 150px;

    .swiperInner {
      margin-left: 80px;
      width: 100%;
      position: absolute;
      left: 0;
    }

    .mySwiper {

      .companyImg {
        position: relative;

        img {
          width: 270px;
          height: 162px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .storyWrap {

    .storyDescription {
      width: 970px;
    }
  }
}

@media (max-width: 768px) {
  .storyWrap {

    .storyDescription {
      width: 750px;
    }
  }
}

@media (max-width: 570px) {
  .storyWrap {

    .storyDescription {
      width: 550px;

      h2 {
        font-size: 3rem;
      }
    }
  }
}

@media (max-width: 500px) {
  .storyWrap {

    .storyDescription {
      width: 300px;
    }

    .swiperWrap {

      .swiperInner {
        margin-left: 40px;
      }
    }
  }
}