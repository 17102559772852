.footer {
  background-color: #212121;
  color: #fff;
  font-size: 14px;

  .footerInner {
    padding: 40px 0;

    .footerTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      .ftrlogo {
        width: 100px;

        a {

          img {
            width: 100%;
          }
        }
      }

      .ftrMenu {

        a {
          font-weight: bold;

          &:first-child {
            margin-right: 20px;
          }
        }
      }
    }

    .footerMiddle {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .companyInfoLeft, .companyInfoRight {
        display: inline-block;
        width: 48%;

        .companyInfo {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          .icon {
            width: 24px;
            height: 24px;
          }

          .cont {
            margin-left: 10px;
          }
        }
      }

    }

    .footerBottom {
      font-size: 14px;
      text-align: center;
    }
  }
}

@media (max-width: 570px) {
.footer {

    .footerInner {

      .footerMiddle {
        display: block;

        .companyInfoLeft,
        .companyInfoRight {
          width: 100%;

          .companyInfo {

            .icon {
              width: 1.5rem;
              height: 1.5rem;
            }

          }
        }

      }
    }
  }
}