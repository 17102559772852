.mb_10 { margin-bottom: 10px; }
.mt_90 { margin-top: 90px; }
.mb_90 { margin-bottom: 90px; }
.pt_90 { padding-top: 90px; }
.pb_90 { padding-bottom: 90px; }
.pt_70 { padding-top: 70px; }

html {
  font-size: 10px;
}

@media (min-width: 350px) {
  html {
  }
}

@media (min-width: 570px) {
  html {
    // font-size: 10px;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 992px) {
  html {
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 14px;
  }
}