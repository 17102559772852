header {
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  padding: 16px 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.432);

  .menuWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 120px;

      img {
        width: 100%;
      }
    }

    ul.menu {
      display: flex;
      justify-content: space-between;
      align-items: center;

      li.menuList {
        font-size: 16px;
        color: #fff;
        cursor: pointer;

        &:nth-child(2) {
          margin-left: 2.125rem;
        }
      }

      .multilingualList {
        position: relative;

        .multilingualSelectWrap {
          display: flex;
          align-items: center;
          margin-left: 2.125rem;
          cursor: pointer;

          .multilingualIcon {
            width: 1.875rem;
            height: 1.875rem;
          }

          .multilingualSelector {
            margin-left: 0.875rem;
            font-size: 16px;
            font-family: 'LGSmartUISB';
            color: #fff;
          }
        }


        .languageDropdownUl {
          margin: 0;
          padding: 0;
          background: rgba(42, 42, 42, 0.786);

          >li {
            position: relative;
            height: 2.875rem;
            border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.618);
            box-sizing: border-box;

            &:last-child {
              border-bottom: none;
            }

            >button {
              position: absolute;
              top: 0; left: 0;
              width: 100%;
              height: 100%;
              border: none;
              background: none;
              font-size: 0.9375rem;
              color: #fff;
              text-align: left;
              padding-left: 0.625rem;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 570px) {
  header {

    .menuWrap {

      .logo {
        width: 9rem;
      }

      ul.menu {

        li.menuList {
          font-size: 1.3rem;
        }

        .multilingualList {

          .multilingualSelectWrap {

            .multilingualSelector {
              font-size: 1.6rem;
              display: none;
            }
          }
        }
      }
    }
  }
}