@import "../../main.scss";

.mainPortfolioWrap {
  background-color: #000;

  h2 {
    text-align: center;
    color: #fff;
    font-size: 3.75rem;
    font-weight: bold;
    padding-bottom: 75px;
  }

  .listWrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1vw;

    .list {
      position: relative;
      height: 200px;

      &:nth-child(odd) {
        background-color: #1D73C2;
      }

      &:nth-child(even) {
        background-color: #41A4FF;
      }

      &::before {
        width: 73px;
        height: 18px;
        scale: 0.8;
        content: "";
        background: url("../../assets/img/main_portfolio_arrow.png");
        background-size: cover;
        position: absolute;
        bottom: 24px;
        right: 26px;
        z-index: 1;
      }

      .listInner {
        width: 100%;
        height: 100%;

        &:hover {

          &::before {
            background-color: #000;
            width: 100%;
            height: 100%;
            content: "";
            background-size: cover;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.4;
          }
        }

      }

      h3 {
        width: 90%;
        color: #fff;
        font-size: 2.125rem;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .plus {
    margin: 70px auto 0;
    width: 70px;
    height: 70px;

    a {
      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 992px) {
  .mainPortfolioWrap {

    .listWrap {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: 570px) {
  .mainPortfolioWrap {

    .listWrap {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}