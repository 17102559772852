@import "../../main.scss";

.listPortfolioWrap {

  .portfolioListBanner {
    position: relative;
    background-image: url(../../assets/img/portfolio_list_banner.jpg);
    background-size: cover;
    height: 27rem;
    margin-bottom: 90px;

    .PortfolioListLogoWrap {
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 27rem;
      }

      h2 {
        color: #fff;
        font-size: 3rem;
        text-align: center;
        margin-top: 10px;
        text-transform: uppercase;
      }
    }

  }

  .PortfolioListLogo {
    font-size: 3.8rem;
    color: #41a4ff;

    img {
      width: 11.5rem;
      // height: 40.4px;
      margin-right: 12px;
    }
  }

  .PortfolioListDesc {
    font-size: 1.9rem;
    color: #878787;
    margin-top: 8px;
    margin-bottom: 6.25rem;
  }

  ul {

    li {
      padding-bottom: 100px;
      margin-bottom: 100px;
      border-bottom: 1px solid rgba(33, 33, 33, 0.3);

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border: none;
      }

      .listInner {
        display: flex;

        .thumbnail {
          width: 340px;
          margin-right: 30px;
        }

        .contents {
          position: relative;

          h3 {
            font-size: 34px;
            font-weight: bold;
            margin-bottom: 10px;
          }

          p {
            font-size: 16px;
            color: #878787;
          }

          .skillWrap {
            display: flex;
            position: absolute;
            bottom: 0;

            .skill {
              margin-right: 10px;
              text-align: center;

              .icon {
                display: block;
                width: 24px;
                margin: 0 auto;
              }

              .iconName {
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .listPortfolioWrap {

    .portfolioListBanner {

      .PortfolioListLogoWrap {

        img {
        }

        h2 {
        }
      }

    }

    .PortfolioListLogo {

      img {
      }
    }

    .PortfolioListDesc {
    }

    ul {

      li {

        &:last-child {
        }

        .listInner {
          display: block;

          .thumbnail {
            margin-bottom: 14px;
          }

          .contents {

            h3 {
            }

            p {
              margin-bottom: 14px;
            }

            .skillWrap {
              position: static;

              .skill {

                .icon {
                  margin-bottom: 0;
                }

                .iconName {
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 570px) {
  .listPortfolioWrap {

    .portfolioListBanner {

      .PortfolioListLogoWrap {

        img {
          width: 21rem;
        }

        h2 {
          font-size: 2rem;
        }
      }

    }

    .PortfolioListLogo {

      img {}
    }

    .PortfolioListDesc {}

    ul {

      li {
        padding-bottom: 50px;
        margin-bottom: 50px;

        &:last-child {}

        .listInner {
          display: block;

          .thumbnail {
            width: 100%;
          }

          .contents {

            h3 {
              font-size: 2.2rem;
            }

            p {
              font-size: 1.4rem;
            }

            .skillWrap {
              position: static;

              .skill {
                text-align: center;

                .icon {
                  margin-bottom: 0;
                  width: 1.5rem;
                }

                .iconName {}
              }
            }
          }
        }
      }
    }
  }
}