.c_panel {
  min-width: 300px;
  max-width: 480px;
  height: 100%;

  margin-right: auto;
  margin-left: auto;
  // padding-left: 15px;
  // padding-right: 15px;

  .c_panelInner {
    height: 100%;
  }
}

@media (min-width: 350px) {
  .c_panel {
    max-width: 300px;
  }
}

@media (min-width: 570px) {
  .c_panel {
    max-width: 550px;
  }
}

@media (min-width: 768px) {
  .c_panel {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  .c_panel {
    max-width: 970px;
  }
}

@media (min-width: 1200px) {
  .c_panel {
    max-width: 1200px;
  }
}