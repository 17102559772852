@charset "utf-8";

/* 여백 초기화 */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
textarea,
p,
blockquote,
th,
td,
input,
select,
button,
figure,
figcaption {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* 폰트 초기화 */
body,
button,
input,
select,
table,
textarea {
  /* font-size: 12px; */
  color: #000;
  font-family: 'Sulphur Point';
}

/* 링크 초기화 */
a {
  color: #000;
  text-decoration: none;
}

/* 목록 초기화 */
ol,
ul {
  list-style: none
}

/* 스타일 초기화 */
strong {
  font-weight: normal;
}

em {
  font-style: normal;
}

/* img {
    width: 100%;
} */
fieldset {
  border: 0 none;
}

button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

/* 테이블 초기화 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* 제목 초기화 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  color: #000;
}

/* 간격 */
.mt0 {
  margin-top: 0;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

/* 이미지 표현 방법 */
/*
    img 태그를 이용해서 표현 --> 이미지에 의미가 있을 때 --> alt(대체 문자 필요)
    background 속성을 이용해서 표현 --> 이미지에 의미가 없을 때(장식용)

    이미지 스플라이트 표현 --> 이미지를 효율적(의미가 있음)으로 쓰기 위한 방법(background, 대체 문자가 없음)
    -> css로 대체 문자를 만들어줌 --> IR 효과
*/

/* ir 효과 */
/* 의미있는 이미지의 대체 텍스트를 제공하는 경우 */
.ir_pm {
  display: block;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
}

/* 의미있는 이미지의 대체 텍스트로 이미지가 없어도 대체 텍스트를 보여주고자 할 때 */
.ir_wa {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: -1;
  width: 100%;
  height: 100%;
}

/* 대체 텍스트가 아닌 접근성을 위한 숨김 텍스트를 제공할 때 */
.ir_so {
  overflow: hidden;
  position: absolute;
  width: 0;
  height: 0;
  line-height: 0;
  text-indent: -9999px;
}