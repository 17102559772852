@import "../../main.scss";

.bannerWrap {
  position: relative;
  height: 100vh;
  border-bottom: 50px solid #000;

  &::before {
    background-color: #000;
    width: 100%;
    height: 100%;
    content: "";
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.6;
  }

  .bannerInner {
    position: relative;
    width: 100%;
    height: 100%;

    .swiperWrap {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    .representativeWrap {
      position: relative;
      width: 100%;
      height: 100%;

        .representativeInner {
          width: 1038px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          .representativeTit {
            color: #fff;
            // font-size: 1.5625rem;
            font-size: 1.7rem;
            text-align: center;
            // font-weight: bold;
            margin-bottom: 80px;
          }

          .representativeBlock {
            color: #fff;
            display: flex;
            align-items: center;

            .icon {
              position: relative;
              width: 150px;
              height: 150px;
              background-color: #fff;
              border-radius: 15px;
              margin-right: 36px;

              img {
                width: 70%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }

            h3 {
              font-size: 3.125rem;
              font-weight: bold;
              color: #fff;
              line-height: 50px;
            }

            p {
              padding: 20px 0;
              font-size: 1.875rem;
              font-weight: normal;
              line-height: 30px;
            }

            em {
              font-size: 1.25rem;
              line-height: 20px;
            }
          }

          .portTabWrap {
            position: relative;
            display: flex;
            justify-content: space-between;
            margin-top: 114px;
            height: 90px;

            .portTab {
              width: 346px;
              height: 90px;
              background-color: #DADADA;
              position: absolute;
              bottom: 0;

              &:nth-child(1) {
                left: 0;
              }

              &:nth-child(2) {
                left: 50%;
                transform: translate(-50%, 0);
              }

              &:nth-child(3) {
                right: 0;
              }

              &.active {
                background-color: #41A4FF;
                height: 117px;
                border: 1px solid;
                border-image: linear-gradient(to bottom, #D1E7FC, #41A4FF);
                border-image-slice: 1;
                box-shadow: (0 0 10px 0 #41A4FF);

                .portTabName {
                  position: relative;
                  color: #fff;

                  &::before {
                    width: 22px;
                    height: 14px;
                    content: "";
                    background: url("../../assets/img/tab_active_arrow.png");
                    background-size: cover;
                    position: absolute;
                    top: 6%;
                    left: 50%;
                    transform: translate(-50%, 0);
                  }
                }
              }

              .portTabName {
                text-align: center;
                line-height: 90px;
                font-size: 1.5625rem;
              }
            }
          }
        }
    }
  }
}

@media (max-width: 1200px) {
.bannerWrap {

    .bannerInner {

      .representativeWrap {

        .representativeInner {
          width: 969px;

          .portTabWrap {

            .portTab {
              width: 323px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .bannerWrap {

    .bannerInner {

      .representativeWrap {

        .representativeInner {
          width: 738px;

          .portTabWrap {

            .portTab {
              width: 246px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .bannerWrap {

    .bannerInner {

      .representativeWrap {

        .representativeInner {
          width: 540px;

          .portTabWrap {

            .portTab {
              width: 180px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 570px) {
  .bannerWrap {

    .bannerInner {

      .representativeWrap {

        .representativeInner {
          width: 288px;

          .representativeTit {
            margin-bottom: 50px;
          }

          .representativeBlock {
            display: block;

            .icon {
              width: 120px;
              height: 120px;
            }

            h3 {
              padding-top: 10px;
            }

            p {
              padding: 0;
            }

            em {}
          }

          .portTabWrap {
            margin-top: 80px;
            height: 80px;

            .portTab {
              width: 96px;
              height: 80px;

              &.active {
                height: 100px;
              }

              .portTabName {
                line-height: 80px;
              }
            }
          }
        }
      }
    }
  }
}