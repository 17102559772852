@import "../../main.scss";

.detailPortfolioIntro, .detailPortfolioGoal, .detailPortfolioPreview {
  // text-align: center;

  h3 {
    font-size: 4rem;
  }
}

.detailPortfolioIntro {
  position: relative;
  padding: 150px 0;
  // background: linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6)), url("../../assets/img/fitness_bg.png");
  background-size: cover;
  // filter: brightness(35%);

  .detailPortfolioPanel {
  }

  &::before {
    // content: "";
    // width: 100%;
    // height: 100%;
    // background: url("../../assets/img/fitness_bg.png");
    // background-size: cover;
    // color: #fff;
    // filter: brightness(35%);
    // position: absolute;
    // top: 0;
    // left: 0;
  }

  .introWrap {
    position: relative;
    color: #fff;
  }

  em {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 1.6rem;
  }

  h2 {
    font-size: 5.6rem;
    font-weight: bold;
    color: #fff;
    margin-bottom: 45px;
  }

  p {
    font-size: 2rem;
    margin-bottom: 100px;
  }

  button {
    font-size: 1.3rem;
    font-weight: bold;
    color: #fff;
    border: 1px solid #fff;
    padding: 19px 45px;
    margin-bottom: 98px;
  }

  ul {
    display: flex;
    margin: 0 auto;
    // width: 500px;
    // margin-bottom: 80px;

    li {
      // width: 300px;
      border-left: 1px solid #fff;
      padding-left: 30px;
      margin-right: 30px;

      .introListInner {

        .introTit {
          font-size: 1.2rem;
          margin-bottom: 20px;
        }

        .introName {
          font-size: 2rem;
        }
      }

    }
  }
}

.detailPortfolioGoal {

  h3 {
    color: #41a4ff;
    margin-bottom: 18px;

    img {
      width: 11.8rem;
      margin-right: 12px;
    }
  }

  .goalContents {
    font-size: 1.875rem;
    color: #878787;
    letter-spacing: -0.9px;
  }

  .goalWrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 9rem auto 0;

    .loaderLineMask {
      position: absolute;
      left: 33.5%;
      top: 0%;
      // transform: translate(-50%, -50%);
      width: 200px;
      height: 400px;
      // margin-left: -60px;
      // margin-top: -60px;
      overflow: hidden;
      transform-origin: 200px 200px;
      -webkit-mask-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
      animation: rotate 2.2s infinite linear;

      .loaderLine {
        width: 400px;
        height: 400px;
        border-radius: 50%;
        box-shadow: inset 0 0 0 24px rgba(80, 151, 210);
      }
    }

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes fade {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0.25;
      }
    }

    @keyframes fade-in {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    .goal {
      position: relative;
      width: 400px;
      height: 400px;

      &:first-child {
        p {
          left: 60%;
        }
      }

      &:nth-child(2) {
        // border: 1px solid #000;
        // border-radius: 50%;
        // box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .1);
        // margin-left: -60px;
        // margin-top: -60px;

        &::before {
          width: 100%;
          height: 100%;
          content: "";
          position: absolute;
          top: 0;
          left: -90%;
          border: 4px solid #41a4ff;
          border-radius: 50%;
        }

        &::after {
          width: 100%;
          height: 100%;
          content: "";
          position: absolute;
          top: 0;
          right: -90%;
          border: 4px solid #41a4ff;
          border-radius: 50%;
        }
      }

      &:nth-child(3) {
        p {
          left: 40%;
        }
      }

      p {
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        font-size: 30px;
        width: 100%;
        text-align: center;
        z-index: 1;
      }
    }
  }
}

.detailPortfolioPreview {
  background-color: rgba(241, 241, 241, 0.5);
  text-align: left;

  h3 {
    color: #41a4ff;
    margin-bottom: 18px;
  }

  p {
    font-size: 1.875rem;
    color: #878787;
    letter-spacing: -0.9px;
    margin-bottom: 5rem;
  }

  .previewWrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3vw;

    &.grid {
      grid-template-columns: repeat(3, 1fr);
    }

    .preview {

      &.hidden {
        display: none;
      }

      img {
        width: 100%;
      }
    }
  }

  .plus {
    display: block;
    margin: 100px auto 0;
    width: 70px;
    height: 70px;

    img {
      width: 100%;
    }
  }
}

@media (max-width: 1300px) {

  .detailPortfolioGoal {

    h3 {

      img {
      }
    }

    .goalContents {
    }

    .goalWrap {
      width: 900px;
      margin: 7rem auto 0;

      .loaderLineMask {
        width: 150px;
        height: 300px;
        transform-origin: 150px 150px;

        .loaderLine {
          width: 300px;
          height: 300px;
        }
      }

      .goal {
        width: 300px;
        height: 300px;

        &:first-child {
          p {
          }
        }

        &:nth-child(2) {

          &::before {
          }

          &::after {
          }
        }

        &:nth-child(3) {
          p {
          }
        }

        p {
          font-size: 1.875rem;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .detailPortfolioGoal {

    h3 {

      img {}
    }

    .goalContents {}

    .goalWrap {
      width: 660px;
      margin: 5rem auto 0;

      .loaderLineMask {
        width: 110px;
        height: 220px;
        transform-origin: 110px 110px;

        .loaderLine {
          width: 220px;
          height: 220px;
          box-shadow: inset 0 0 0 16px rgba(80, 151, 210);
        }
      }

      .goal {
        width: 220px;
        height: 220px;

        &:first-child {
          p {}
        }

        &:nth-child(2) {

          &::before {}

          &::after {}
        }

        &:nth-child(3) {
          p {}
        }

        p {
          font-size: 1.875rem;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .detailPortfolioIntro {

    .detailPortfolioPanel {
    }

    &::before {
    }

    .introWrap {
    }

    em {
    }

    h2 {
    }

    p {
    }

    button {
    }

    ul {
      display: block;

      li {
        margin-bottom: 30px;

        .introListInner {

          .introTit {
            margin-bottom: 10px;
          }

          .introName {
          }
        }

      }
    }
  }

  .detailPortfolioGoal {

    h3 {

      img {}
    }

    .goalContents {}

    .goalWrap {
      width: 480px;

      .loaderLineMask {
        width: 80px;
        height: 160px;
        transform-origin: 80px 80px;

        .loaderLine {
          width: 160px;
          height: 160px;
        }
      }

      .goal {
        width: 160px;
        height: 160px;

        &:first-child {
          p {}
        }

        &:nth-child(2) {

          &::before {}

          &::after {}
        }

        &:nth-child(3) {
          p {}
        }

        p {
          font-size: 1.875rem;
        }
      }
    }
  }
}

@media (max-width: 570px) {
  .detailPortfolioIntro {
    padding: 90px 0;

    .detailPortfolioPanel {}

    &::before {}

    .introWrap {
    }

    em {
      font-size: 1.6rem;
    }

    h2 {
      font-size: 4.6rem;
      margin-bottom: 35px;
    }

    p {
      font-size: 2rem;
      margin-bottom: 30px;
    }

    button {
      margin-bottom: 40px;
    }

    ul {
      display: block;

      li {

        .introListInner {

          .introTit {
            font-size: 1.4rem;
          }

          .introName {
            font-size: 1.8rem;
          }
        }

      }
    }
  }

  .detailPortfolioGoal {

    h3 {

      img {}
    }

    .goalContents {
      font-size: 1.5rem;
    }

    .goalWrap {
      width: 297px;

      .loaderLineMask {
        width: 49.5px;
        height: 99px;
        transform-origin: 49.5px 49.5px;

        .loaderLine {
          width: 99px;
          height: 99px;
          box-shadow: inset 0 0 0 10px rgba(80, 151, 210);
        }
      }

      .goal {
        width: 99px;
        height: 99px;

        &:first-child {
          p {}
        }

        &:nth-child(2) {

          &::before {}

          &::after {}
        }

        &:nth-child(3) {
          p {}
        }

        p {
          font-size: 1.2rem;
        }
      }
    }
  }

  .detailPortfolioPreview {

    h3 {
      margin-bottom: 1.25rem;
    }

    p {
      font-size: 1.5rem;
    }

    .previewWrap {
      display: block;

      .preview {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        &.hidden {
        }

        img {
        }
      }
    }

    .plus {

      img {
      }
    }
  }
}