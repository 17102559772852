/* fade in */
@keyframes slideFadeInDropdown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

.slideFadeInDropdown {
  overflow: hidden;
}

.slideFadeInDropdown>ul {
  animation: slideFadeInDropdown .4s ease;
}

/* fade out */
@keyframes slideFadeOutDropdown {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

.slideFadeOutDropdown {
  overflow: hidden;
}

.slideFadeOutDropdown>ul {
  animation: slideFadeOutDropdown 0.4s ease;
  animation-fill-mode: forwards;
}

.slideFadeInDropdown, .slideFadeOutDropdown {
  position: absolute;
  top: 43px;
  left: 30px;
  width: 90%;
}

@media (max-width: 570px) {
  .slideFadeInDropdown, .slideFadeOutDropdown {
    left: 16px;
    width: 130%;
  }
}