@import "../../main.scss";

//Home
.introduceWrap {
  color: #fff;

  .introduceInner {
    position: relative;

    &::after {
      width: 100%;
      height: 100%;
      content: "";
      background: url("../../assets/img/introduceSect_bg.png");
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    .introduceListWrap {

      .introduceList {
        text-align: center;

        .descriptionWrap {
          h2 {
            font-size: 3.75rem;
            font-weight: bold;
            color: #41a4ff;
          }

          p {
            padding: 20px 0;
            font-size: 1.875rem;
            color: #d7d7d7;
          }
        }

        .deviceWrap {
          display: flex;
          justify-content: space-between;
          margin-top: 50px;

          .device {
            position: relative;
            width: 16.875rem;
            height: 16.875rem;
            border: 4px solid #41a4ff;
            border-radius: 50%;

            h3 {
              text-align: center;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: #fff;
              font-size: 2.25rem;
            }
          }
        }
      }
    }
  }
}


@media (max-width: 992px) {
  .introduceWrap {

    .introduceInner {

      .introduceListWrap {

        .introduceList {

          .deviceWrap {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 1vw;
            justify-items: center;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .introduceWrap {

    .introduceInner {

      .introduceListWrap {

        .introduceList {

          .deviceWrap {

            .device {
              width: 220px;
              height: 220px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 570px) {
  .introduceWrap {

    .introduceInner {

      .introduceListWrap {

        .introduceList {

          .descriptionWrap {

            h2 {
              font-size: 3rem;
            }

            p {
              font-size: 1.3rem;
            }
          }

          .deviceWrap {


            .device {
              width: 160px;
              height: 160px;
              border: 2px solid #41a4ff;

              h3 {
                font-size: 2rem;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .introduceWrap {

    .introduceInner {

      .introduceListWrap {

        .introduceList {

          .deviceWrap {

            .device {
              width: 140px;
              height: 140px;
            }
          }
        }
      }
    }
  }
}