@import "../../main.scss";

.walfareDetailWrap {
    margin-top: 140px;
    font-size: 16px;

  .walfareDetailTit {
    font-size: 3.4rem;
    margin-bottom: 6.25rem;
    color: #41a4ff;
  }

  .walfareListWrap {

    .walfareList {
      padding-bottom: 100px;
      margin-bottom: 100px;
      border-bottom: 1px solid rgba(33, 33, 33, 0.3);

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border: none;
      }

      .listInner {
        display: flex;

        .thumbnail {
          height: 280px;
          margin-right: 30px;
          border-radius: 20px;
          border: 1px solid #ddd;
        }

        .contents {
          position: relative;

          h4 {
            font-size: 1.9rem;
            font-weight: bold;
            margin-bottom: 1.2rem;
          }

          p {
            font-size: 1.6rem;
            color: #808080;
            margin-bottom: 1.2rem;
          }

          .infoListWrap {

            .infoList {
              position: relative;
              margin-bottom: 8px;
              margin-left: 25px;

              &:nth-child(1) {
                &::before {
                  content: "◎";
                  position: absolute;
                  left: -25px;
                }
              }

              &::before {
                content: "▷";
                position: absolute;
                left: -25px;
              }

              span {
                font-weight: bold;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .walfareDetailWrap {

    .walfareDetailTit {
    }

    .walfareListWrap {

      .walfareList {

        .listInner {
          display: block;

          .thumbnail {
            margin-bottom: 14px;
          }

          .contents {

            h4 {
            }

            p {
              margin-bottom: 14px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 570px) {
  .walfareDetailWrap {
    margin-top: 100px;

    .walfareDetailTit {}

    .walfareListWrap {

      .walfareList {

        .listInner {

          .thumbnail {
            width: 100%;
            height: auto;
          }

          .contents {

            h4 {
              font-size: 2.2rem;
            }

            p {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
}